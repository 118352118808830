// noinspection JSNonASCIINames,NonAsciiCharacters

export default {
  'Enviar pedido de foto camera': 'Envoyer une demande de photo appareil',
  'Condução Semanal': 'Conduite hebdomadaire',
  'Origen': 'Départ',
  'Destino': 'Destination',
  'Con relleno': 'Avec remplissage',
  'menos de 20%': 'moins de 20%',
  'entre 20% a 40%': 'Entre 20% et 40%',
  'mais de 40%': 'plus de 40%',
  'Eliminar geocercas': 'Supprimer des zones',
  'Delete all zones?': 'Êtes-vous sûr de vouloir supprimer toutes les barrières géographiques de ce groupe?',
  viewEventsOnRoute: 'Afficher les événements sur l\'itinéraire',
  viewIdlePoints: 'Afficher les points inactifs sur l\'itinéraire',
  Veículos: 'Véhicules',
  'Inside or outside zones': 'Dans et hors des zones',
  'Only inside zones': 'Seulement dans les zones',
  'Only oustside zones': 'Uniquement hors des zones',
  Nome: 'Nom',
  'Relatórios personalizados': 'Rapports personnalisés',
  'Show poi circles': 'Afficher les cercles poi',
  'Última hora': 'Denière heure',
  'Últimas 6 horas': 'Denière 6 heures',
  'Últimas 12 horas': 'Denière 12 heures',
  'Últimas 24 horas': 'Denière 24 heures',
  'Associated Group': 'Groupe associé',
  Copiado: 'Copié',
  Move: 'Déplacer',
  copyClipboardMessage: 'copié dans le presse-papier.',
  app: {
    connectionLost: 'Conexion terminada',
    reconnect: 'Desea restabelecer la conexión con el servidor?'
  },
  tripsTable: {
    trip: 'Voyage',
    trips: 'Voyages',
    total: 'Totaux'
  },
  route: {
    map: 'Map',
    dashboard: 'Dashboard',
    reports: 'Rapports',
    report: 'Raport',
    report_trip_title: 'Rapport de Voyage',
    report_location_title: 'Rapport de Localisation',
    report_zone_crossing: 'Rapport de Zone',
    report_speeding: 'Rapport de Survitesse',
    report_refueling: 'Rapport de Ravitaillement',
    report_fueldrop: 'Rapport de Perte de Carburant',
    report_speeding_beta: 'Relatório de Excessos de Velocidade BETA',
    report_events: 'Rapport d\'Événements',
    report_activity: 'Rapport d\'Activité',
    report_tolls: 'Relatório de Portagens',
    report_timerange: 'Informe uso fuera de horario',
    customreport_vistawaste_activity_title: 'Relatório de Actividade Vista Waste',
    settings: 'Paramètres',
    duration: 'Duration',
    nodata: 'Não existem dados para o período seleccionado.',
    alerts: 'Alertes',
    custom_reports: 'Relatórios Custom',
    maintenance: 'Módulo de Manutenção',
    scheduler: 'Rapports Automatiques',
    manual: 'Manual',
    legacy: 'Vieille Carte',
    tacho: 'Tachygraphe'
  },
  map: {
    geofence_create_error: 'Error creating geofence',
    poi_create_error: 'Error creating POI',
    geofence_create_title: 'New geofence',
    geofence_create_name: 'Please enter the name of this geofence...',
    geofence_created: 'Geofence created sucessfully!',
    geofence_create_canceled: 'Input canceled',
    poi_create_title: 'Nouveau POI',
    poi_create_name: 'Veuillez saisir le nom de ce POI......',
    poi_created: 'POI created sucessfully!',
    poi_create_canceled: 'Input canceled',
    create_confirm: 'OK',
    create_cancel: 'Annuler',
    loading: 'Loading',
    totalDistance: 'Total Distance',
    poi_click_on_map: 'Click the point on the map where you want to create the POI',
    poi_move_on_map: 'Faites glisser le marqueur vers le nouvel emplacement et cliquez sur le marqueur pour enregistrer la modification',
    move_poi_title: 'Déplacer POI',
    confirm_move_poi: 'Voulez-vous déplacer le POI vers cet emplacement?',
    send_immobilization_command_ok: 'Commande envoyée avec succès!',
    line_create_canceled: 'Line creation canceled!'
  },
  vehicleList: {
    title: 'Véhicule',
    search: 'Recherche...',
    empty_list: 'Nothing found',
    column_name: 'Nom',
    column_speed: 'Km/h',
    column_lastUpdate: 'Last update',
    order_by_status: 'Ordre par État',
    order_by_vehicle: 'Ordre par Véhicule',
    order_by_group: 'Ordre par Groupe',
    order_by_last_update: 'Ordre par Dernière Actualisation',
    order_by_fuel_level: 'Ordre par Niveau de Combustible',
    order_by: 'trié par...'
  },
  vehicleTable: {
    immobilize: 'Immobilize',
    de_immobilize: 'De-Immobilize',
    send_immobilization: 'Send immobilization command?',
    send_de_immobilization: 'Send de-immobilization command?',
    all_vehicles: 'Tout',
    moving_vehicles: 'En Marche',
    idle_vehicles: 'Ralenti',
    stopped_vehicles: 'Arrêter',
    disconnected_vehicles: 'Déconnecté',
    immo_pending: 'There is already a pending command for this vehicle. Please wait for the result.'
  },
  vehicleDetail: {
    show_route: 'Voir l\'itinéraire',
    follow: 'Seguir',
    unfollow: 'Parar'
  },
  poiTable: {
    showPOIs: 'Show POIs',
    edit_poi: 'Éditer',
    delete_poi: 'Supprimer',
    hide_geofence: 'Masquer geofence',
    hide_all: 'Cacher tout',
    show_geofence: 'Afficher geofence',
    show_all: 'Afficher tout'
  },
  dashboard: {
    startdate: 'Start date',
    enddate: 'End Date',
    period_lastweek: 'Last week',
    period_lastmonth: 'Last month',
    period_last3month: 'Last 3 months'
  },
  navbar: {
    profile: 'Profil',
    notifications: 'Notifications',
    settings: 'Paramètres',
    logout: 'Log Out'
  },
  login: {
    register: 'Créer un compte',
    forgotPassword: 'Mot de pass oubliée?',
    login_user: 'E-mail',
    login_password: 'Mot de pass',
    login_button: 'Connexion',
    login_password_warn: 'The password can not be less than 6 digits',
    login_user_password_invalid: 'There is no account with that username or password you entered. Please check your credentials and try again.',
    login_user_disabled: 'Username is disabled'
  },
  alerts: {
    title: 'Alertes',
    get_alerts: 'Get Notifications'
  },
  profile: {
    user_account: 'Account',
    user_name: 'Nom',
    user_email: 'E-mail',
    user_password: 'Mot de pass',
    user_phone: 'Téléphone',
    user_language: 'Langue',
    user_timezone: 'Timezone',
    user_update_button: 'Update',
    user_updated: 'User information has been updated successfully.',
    user_name_required: 'Name field is required',
    user_email_required: 'Please input a valid email',
    user_password_lengh: 'The password can not be less than 8 digits',
    user_reports: 'Relatórios por email',
    daily_reports: 'Receber diariamente',
    weekly_reports: 'Receber semanalmente',
    monthly_reports: 'Receber mensalmente',
    continue: 'Continuar?',
    unsaved_changes: 'Existem alterações que não foram guardadas',
    inactive_vehicles_email: 'Alerte d\'inactivité de l\'équipement',
    inactive_vehicles_email_tooltip: 'Un email d\'alerte sera envoyé indiquant l\'équipement qui ne communique pas pendant plus de 48 heures',
    daily_reports_tooltip: 'Email enviado diariamente com informação da actividade dos veículos',
    weekly_reports_tooltip: 'Email enviado semanalmente com informação da actividade dos veículos',
    monthly_reports_tooltip: 'Email enviado mensalmente com informação da actividade dos veículos',
    user_type_admin: 'Administrator',
    user_type_manager: 'Manager',
    user_type_operator: 'Operator'
  },
  'Use road speed limit': 'Utiliser la limite de vitesse sur route',
  Generator: 'Generator',
  Forklift: 'Forklift',
  settings: {
    alert_alarmWeeklyDriving: 'Conduite hebdomadaire',
    alert_sensor: 'Capteur ',
    alert_temperature: 'Température',
    vehicle_icon_mixer: 'Mixer',
    vehicle_icon_cistern: 'Cistern',
    vehicle_icon_bulldozer: 'Bulldozer',
    vehicle_icon_minibus: 'Minibux',
    vehicle_icon_dumpingtruck: 'Dumping truck',
    vehicle_icon_crane: 'Crane',
    vehicle_icon_roadroller: 'Road roller',
    vehicle_icon_digger: 'Digger',
    vehicle_route_color: 'Couler',
    vehicle_icon_trailer: 'Trailer',
    vehicle_associated_driver: 'Conducteur',
    vehicle_icon_towtruck: 'Dépanneuse',
    max_temperature: 'température maximale',
    min_temperature: 'température minimale',
    vehicle_icon_ambulance: 'Ambulance',
    maintenance: 'Maintenance',
    select: 'Selecctioner',
    calibrate_temperature: 'Calibrer la température',
    alert_digital_port: 'Capteur',
    max_digital_port_open_time: 'Temps maximum d\'ouverture du capteur',
    vehicle_edit_kms: 'Modifier les km du véhicule',
    vehicle_get_qrcode: 'QR Code',
    alert_alarm: 'Toute',
    alert_alarmEngineOn: 'Conduite continue',
    alert_alarmEngineOff: 'Arrêt long',
    labelTextSize: 'Taille du texte',
    user_form_manage_devices: 'Utilisateur administrateur',
    commands: 'Commandes',
    modules: 'Modules',
    createTempPassword: 'Generate temporary password',
    icons3d: '3D icons',
    showLabels: 'Afficher le nom des véhicules',
    showPOIsLabels: 'Afficher le nom de POI',
    reducePoiSize: 'Réduire la taille des Poi lors d\'un zoom arrière',
    search: 'Recherche...',
    empty_list: 'Rien n\'a été trouvé',
    refresh: 'Recarregar aplicação',
    version: 'Version',
    connected: 'Connecté',
    disconnected: 'Déconnecté',
    connection: 'Connection',
    map: 'Map',
    about: 'About',
    logout: 'Logout',
    vehicles: 'Véhicules',
    pois: 'POIs',
    geofences: 'Geofences',
    title: 'Paramètres',
    route_history: 'History Route',
    route_match: 'Route match',
    showFullDate: 'Afficher la date/heure du dernier emplacement',
    view_speed_alerts: 'Afficher les alertes de vitesse',
    use_route_speed_limit: 'Use route speed limit',
    use_vehicle_speed_limit: 'Use vehicle speed limit',
    max_speed_threshold: 'Max speed threshold in Km/h',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    form_cancel: 'Annuler',
    form_confirm: 'Confirm',
    form_save: 'Sauvegarder',
    transfer_selected: 'Sélectionnés',
    configuration: 'Paramètres',
    time: 'Temps',
    emails: 'Outros Emails',
    other_emails: 'Autres e-mails (séparés par une virgule)',
    alerts: 'Alertes',
    alarms: 'Alertes',
    alerts_type: 'Type',
    alerts_notificators: 'Notifications',
    alert_ignitionOff: 'Arrêt',
    alert_ignitionOn: 'Démarrage',
    alert_geofenceEnter: 'Entrée zone',
    alert_geofenceExit: 'Sortie zone',
    alert_deviceOverspeed: 'Excès de vitesse',
    alert_sos: 'SOS',
    alert_unidentifiedDriver: 'Conducteur non identifié',
    alert_gpsAntennaCut: 'Antenne GPS déconnectée',
    alert_tow: 'Remorquage',
    alert_highRpm: 'Moteur élevé (RPM)',
    alert_hardAcceleration: 'Accélèration Brusque',
    alert_hardBraking: 'Freinage Brusque',
    alert_hardCornering: 'Virage Brusque',
    alert_powerCut: 'Perte d\'alimentation externe',
    alert_shock: 'Shock',
    alert_maintenance: 'Maintenance',
    alert_idle: 'Ralenti',
    alert_deviceFuelDrop: 'Chute de carburant',
    alert_driverChanged: 'Changement conducteur',
    alert_deleted: 'Alert has been deleted',
    alert_delete_info: 'Do you want to delete the alert ',
    alert_delete_title: 'Delete Alert',
    alert_edit_confirm: 'Confirm',
    alert_edit_cancel: 'Annuler',
    alert_created: 'Alert created sucessfully!',
    alert_updated: 'Alert updated sucessfully!',
    alert_add: 'Ajouter une alerte',
    alert_edit: 'Edit Alert',
    alert_delete: 'Delete Alert',
    alert_overspeed_warning: 'Vehicle without defined top speed',
    alert_geofences_warning: 'Vehicle without associated geofences',
    alert_form_type: 'Type:',
    alert_form_type_placeholder: 'Select alert type',
    alert_form_vehicles: 'Véhicules:',
    alert_form_geofences: 'Geofences:',
    alert_form_all_vehicles: 'Tous les véhicules',
    alert_form_vehicles_placeholder: 'Select vehicles',
    alert_form_notificator_web: 'Web',
    alert_form_notificator_email: 'E-mail',
    alert_form_notificator_sms: 'SMS',
    alert_form_confirm: 'Sauvegarder',
    alert_form_cancel: 'Annuler',
    alert_form_geofences_placeholder: 'Select geofences',
    alert_form_pois_placeholder: 'Select POIs',
    alert_form_linegeofences_placeholder: 'Select line geofences',
    alert_form_select_all: 'Tout Sélectionner',
    alert_form_deselect_all: 'Enlever Tout',
    alert_form_ignitionoff_max_duration: 'Durée Maximale (Minutes)',
    alert_warning_type: 'This type of alert depends on vehicle\'s unit',
    alert_form_notificator_select_on: 'Veuillez sélectionner au moins un',
    vehicle_edit: 'Edit Vehicle',
    vehicle_name: 'Nom',
    vehicle_licenseplate: 'Plaque d\'Immatriculation',
    vehicle_model: 'Modèle',
    vehicle_form_category: 'Icône',
    vehicle_form_total_kms: 'Kms Totaux',
    vehicle_form_fuel_tank_capacity: 'Capacité du Réservoir (L)',
    vehicle_speed_limit: 'Limites de Vitesse (Km/h)',
    vehicle_form_name: 'Nom',
    vehicle_form_group: 'Groupe',
    vehicle_form_groups_placeholder: 'Sélectionner un Groupe',
    vehicle_form_model: 'Modèle',
    vehicle_form_speed_limit: 'Limites de Vitesse (Km/h)',
    vehicle_form_speed_minimal_duration: 'Durée minimale (minutes)',
    vehicle_form_by_date: 'Set by date',
    vehicle_form_apply_to_all: 'Appliquer la configuration sur tous les véhicules.',
    vehicle_kms_form: 'Edit Vehicle Kms',
    vehicle_kms_form_date: 'Data',
    vehicle_kms_form_total_kms: 'Total Kms',
    vehicle_total_hours: 'Total Hours',
    vehicle_kms_form_date_placeholder: 'Select date and time',
    vehicle_kms: 'Véhicule Kms',
    vehicle_lastposition: 'Dernière Position',
    vehicle_lastposition_address: 'Adresse',
    vehicle_notes: 'Remarques',
    vehicle_gsm: 'GSM',
    vehicle_kms_traveled: 'Traveled since',
    vehicle_kms_current: 'Current',
    vehicle_updated: 'Véhicule mis à jour avec succès!',
    vehicle_geofences_updated: 'Geofences associées au véhicule mises à jour avec succès!',
    vehicle_group: 'Groupe',
    vehicle_icon_car: 'Car',
    vehicle_icon_truck: 'Truck',
    vehicle_icon_truck2: 'Truck 2',
    vehicle_icon_van: 'Van',
    vehicle_icon_default: 'Default',
    vehicle_icon_bicycle: 'Bicycle',
    vehicle_icon_person: 'Person',
    vehicle_icon_arrow: 'Arrow',
    vehicle_icon_bus: 'Bus',
    vehicle_icon_tractor: 'Tractor',
    vehicle_icon_helicopter: 'Helicopter',
    vehicle_icon_motorcycle: 'Motorcycle',
    vehicle_icon_boat: 'Boat',
    vehicle_icon_pickup: 'Pickup',
    vehicle_edit_not_allowed: 'You do not have permissions to edit the vehicle.',
    vehicle_associate_geofences: 'Associate Geofences',
    vehicle_form_category_placeholder: 'Sélectionner l\'Icône',
    speed_limit: 'Limites de Vitesse',
    unidentified_driver_duration: 'Minimal Duration (minutes)',
    geofences_title: 'Geofences',
    geofence_name: 'Nom',
    geofence_description: 'Description',
    geofence_speed_Limit: 'Limites de Vitesse (Km/h)',
    geofences_type_geofences: 'Geofence:',
    geofences_type_linegeofences: 'Line Geofences:',
    geofences_type_pois: 'POIs:',
    geofence_icon_square: 'Carré',
    geofence_icon_triangle: 'Triangle',
    geofence_icon_airport: 'Airport',
    geofence_icon_aquarium: 'Aquarium',
    geofence_icon_attraction: 'Viewpoint',
    geofence_icon_barrier: 'Barrier',
    geofence_icon_building_alt1: 'Tall Building',
    geofence_icon_building: 'Building',
    geofence_icon_car_rental: 'Car Rental',
    geofence_icon_car_repair: 'Car Repair',
    geofence_icon_castle: 'Castle',
    geofence_icon_cemetery: 'Cemetery',
    geofence_icon_charging_station: 'Charging Station',
    geofence_icon_circle: 'Circle',
    geofence_icon_city: 'City',
    geofence_icon_embassy: 'Flag',
    geofence_icon_fuel: 'Fuel Station',
    geofence_icon_home: 'Home',
    geofence_icon_industry: 'Industry',
    geofence_icon_information: 'Information',
    geofence_icon_marker: 'Marker',
    geofence_icon_marker_stroked: 'Marker',
    geofence_icon_parking: 'Parking',
    geofence_icon_parking_garage: 'Parking Garage',
    geofence_icon_ranger_station: 'Fire Station',
    geofence_icon_recycling: 'Recycling',
    geofence_icon_residential_community: 'Residential',
    geofence_icon_star: 'Star',
    geofence_icon_town: 'Town',
    geofence_icon_town_hall: 'Town Hall',
    geofence_icon_village: 'Village',
    geofence_icon_warehouse: 'Warehouse',
    geofence_icon_waste_basket: 'Waste Basket',
    geofence_icon_windmill: 'Windmill',
    geofence_edit_title: 'Edit Zone',
    geofence_form_name: 'Nom',
    geofence_form_description: 'Description',
    geofence_form_icon: 'Icon',
    geofence_form_color: 'Color',
    geofence_form_options: 'Options',
    geofence_form_speedlimit: 'Limites de Vitesse (Km/h)',
    geofence_form_distance: 'Distance maximale',
    geofence_form_distance_apply_to_all: 'Appliquer la distance à tous les POIs',
    geofence_form_color_apply_to_all: 'Appliquer la color à tous les POIs',
    zone: 'Zones',
    groups: 'Groupes',
    group_edit: 'Changer de groupe',
    group_add: 'Add Group',
    group_name: 'Nom',
    group_description: 'Description',
    group_form_name: 'Nom',
    group_form_description: 'Description',
    group_updated: 'Group updated sucessfully!',
    group_deleted: 'Group has been deleted.',
    group_delete_info: 'Do you want to delete the group ',
    group_delete_title: 'Delete Group',
    group_edit_confirm: 'Confirm',
    group_edit_cancel: 'Annuler',
    group_created: 'Group created sucessfully!',
    group_select_vehicles_placeholder: 'Select Vehicles',
    group_select_drivers_placeholder: 'Select Drivers',
    group_select_geofences_placeholder: 'Select Geofences',
    group_select_pois_placeholder: 'Select POIs',
    group_select_linegeofences_placeholder: 'Select LineGeofences',
    group_add_not_allowed: 'You do not have permission to add groups.',
    group_edit_not_allowed: 'Vous n\'avez pas l\'autorisation de changer de groupes.',
    group_delete_not_allowed: 'You do not have permission to delete groups.',
    drivers: 'Conducteurs',
    driver_name: 'Nom',
    driver_uniqueId: 'RFID Key',
    driver_email: 'E-Mail',
    driver_phone: 'Téléphone',
    driver_notes: 'Remarques',
    driver_add: 'Ajouter',
    driver_add_title: 'Ajouter Conducteurs',
    driver_edit: 'Éditer',
    driver_edit_title: 'Éditer Conducteurs',
    driver_delete: 'Supprimer',
    driver_form_cancel: 'Annuler',
    driver_form_confirm: 'Confirm',
    driver_deleted: 'Driver has been deleted.',
    driver_delete_info: 'Do you want to delete the driver ',
    driver_delete_title: 'Supprimer Conducteurs',
    driver_created: 'Driver created sucessfully!',
    driver_updated: 'Driver updated sucessfully!',
    driver_add_not_allowed: 'You do not have permission to add drivers.',
    driver_edit_not_allowed: 'You do not have permission to edit drivers.',
    driver_delete_not_allowed: 'You do not have permission to delete drivers.',
    driver_uniqueid_create: 'Créer',
    driver_uniqueid_create_label: 'Créer un identifiant',
    driver: 'Conducteur',
    reports: 'Rapports',
    automatic_reports: 'Rapports Automatiques',
    activate_automatic_trip_report: 'Relatório de viagens - Activar relatório automático',
    activate_automatic_location_report: 'Relatório de posições - Activar relatório automático',
    activate_automatic_zone_report: 'Relatório de zonas - Activar relatório automático',
    activate_automatic_speeding_report: 'Relatório de excesso de velocidade - Activar relatório automático',
    activate_automatic_refueling_report: 'Relatório de abastecimentos - Activar relatório automático',
    activate_automatic_fueldrop_report: 'Fuel drop report - Enable automatic report',
    report_periodicity_daily: 'Recevez quotidiennement',
    report_periodicity_weekly: 'Recevez chaque semaine',
    report_periodicity_monthly: 'Recevez mensuellement',
    report_by_group: 'Recevez un e-mail pour chaque groupe de véhicules',
    uniqueid_duplicated: 'Clé RFID dupliquée',
    pois_title: 'POIs',
    add: 'Ajouter',
    edit: 'Éditer',
    delete: 'Supprimer',
    name_required: 'Name field is required',
    email_format_invalid: 'Please input a valid email',
    email_or_phone_required: 'At least one of these fields must have a valid value.',
    users: 'Utilisateurs',
    user_edit: 'Éditer Utilisateurs',
    user_add: 'Ajouter Utilisateurs',
    user_name: 'Nom',
    user_email: 'Email',
    user_phone: 'Téléphone',
    user_language: 'Langue',
    user_timezone: 'Timezone',
    user_type: 'Profil',
    user_password: 'Password',
    user_form_type_placeholder: 'Select Profile',
    user_deleted: 'User has been deleted.',
    user_delete_info: 'Do you want to delete the user ',
    user_delete_title: 'Delete User',
    user_created: 'User created sucessfully!',
    user_updated: 'User updated sucessfully!',
    user_create_not_allowed: 'You do not have permissions to create users.',
    user_edit_not_allowed: 'You do not have permissions to edit users.',
    user_duplicated_entry: 'User already exists.',
    temporary_links: 'Liens Temporaire',
    expiration_date: 'Date d\'expiration',
    user_temporary_link: 'Lien',
    temporary_link_add: 'Créer un Lien Temporaire',
    temporary_link_edit: 'Modifier le Lien Temporaire',
    temporary_link_created: 'Lien temporaire créé avec succès!',
    temporary_link_create_not_allowed: 'Vous n\'êtes pas autorisé à créer des liens temporaires.',
    temporary_link_deleted: 'Le lien temporaire a été supprimé.',
    temporary_link_delete_info: 'Voulez-vous supprimer le lien temporaire ',
    temporary_link_delete_title: 'Supprimer le lien temporaire',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    full: 'Compléter',
    partial: 'Partiel',
    selectStartTime: 'Sélectionnez les heures de début',
    selectEndTime: 'Sélectionnez les heures de fin',
    edit_vehicle_kms_warning: 'Dans ce véhicule, le calcul des km se fait directement sur l\'équipement, pour mettre à jour les km il faut changer sa configuration. Veuillez contacter le support.'
  },
  geofence: {
    showGeofences: 'Show Geofences',
    geofence_name: 'Nom',
    geofence_edit: 'Éditer',
    geofence_delete: 'Supprimer',
    geofence_deleted: 'Geofence has been deleted',
    geofence_delete_info: 'Do you want to delete geofence ',
    geofence_delete_title: 'Delete Geofence',
    geofence_edit_title: 'Edit Geofence',
    geofence_edit_name: 'Please enter the name of this geofence...',
    geofence_edit_confirm: 'Confirm',
    geofence_edit_cancel: 'Annuler',
    geofence_edit_canceled: 'Edit canceled',
    geofence_edited: 'Geofence edited sucessfully!',
    poi_delete_info: 'Do you want to delete POI ',
    poi_delete_title: 'Delete POI',
    poi_edited: 'POI edited sucessfully!',
    poi_deleted: 'POI has been deleted',
    poi_edit_title: 'Edit POI',
    poi_edit_name: 'Please enter the name of this POI...',
    searchGeofence: 'Recherche geofence',
    linegeofence_delete_info: 'Do you want to delete geofence line ',
    linegeofence_delete_title: 'Delete Geofence Line',
    linegeofence_edited: 'Geofence line edited sucessfully!',
    linegeofence_deleted: 'Geofence line deleted',
    linegeofence_edit_title: 'Edit Geofence Line',
    linegeofence_edit_name: 'Please enter the name of this geofence line...',
    edit_geofence: 'Éditer',
    delete_geofence: 'Supprimer'
  },
  report: {
    selector_search: 'Recherche',
    select_vehicles: 'Sélectionnés',
    select_vehicles_placeholder: 'Véhicules',
    select_groups: 'Sélectionnés',
    select_groups_placeholder: 'Groupes',
    select_geofences: 'Sélectionnés',
    select_geofences_placeholder: 'Geofences',
    select_period: 'Select period',
    period: 'Period',
    date_start: 'Start date',
    date_end: 'End date',
    generate_report: 'Generate report',
    validate_period: 'Please select a period',
    select_all: 'All'
  },
  layout: {
    deviceOnline: 'Device Online',
    deviceMoving: 'Device Moving',
    deviceStopped: 'Device Stopped',
    ignitionOff: 'Ignition Off',
    ignitionOn: 'Ignition On',
    deviceOverspeed: 'Device Overspeed',
    geofenceEnter: "Alerte d'entrée zone",
    geofenceExit: 'sortie zone',
    test: 'Test Notification',
    newVersion: 'A new version of this app is available. Click here to update.'
  },
  permissions: {
    customreport_vistawaste_activity:	'Actividade Vistawaste',
    externalmaintenance: 'Módulo Externo de Manutenção',
    externalreports: 'Módulo Externo de Relatórios',
    externalreports_activity_cocacola_day: 'Actividade Coca-Cola diário',
    externalreports_activity_cocacola_month: 'Actividade Coca-Cola mensal',
    externalreports_activity_cocacola_month_detailed: 'Actividade Coca-Cola mensal detalhado',
    externalreports_luanda_speeding: 'Velocidades em localidade',
    externalreports_passenger_pickup_per_passenger: 'Recolhas por passageiro',
    externalreports_passenger_pickup_per_passenger_total: 'Recolhas totais por passageiro',
    externalreports_passenger_pickup_per_vehicle: 'Recolhas por veículo',
    externalreports_passenger_pickup_per_vehicle_total: 'Recolhas totais por veí­culo',
    externalreports_report_group_km: 'Distâncias por grupo',
    externalreports_report_km: 'Kms',
    externalreports_report_misuse: 'Utilização indevida',
    externalreports_rfid_cards: 'Configuração de cartões',
    externalreports_rfid_readers: 'Configuração de leitores',
    externalreports_usage: 'Utilização Resumido',
    externalreports_wapo_speeding: 'Velocidade WAPO'
  }
}
